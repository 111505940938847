export enum ACTIVITY_TYPE {
  ETH_DEPOSITED_BY_STAKER = 'ETH_DEPOSITED_BY_STAKER',
  LP_BURNED_FOR_ETH = 'LP_BURNED_FOR_ETH',
  GIANT_LP_SWAPPED = 'GIANT_LP_SWAPPED',
  SMART_WALLET_CREATED = 'SMART_WALLET_CREATED',
  NEW_VALIDATOR_REGISTERED = 'NEW_VALIDATOR_REGISTERED',
  LP_TOKEN_ISSUED = 'LP_TOKEN_ISSUED',
  LP_TOKEN_MINTED = 'LP_TOKEN_MINTED',
  KNOT_STAKED = 'KNOT_STAKED',
  STAKEHOUSE_CREATED = 'STAKEHOUSE_CREATED',
  STAKEHOUSE_JOINED = 'STAKEHOUSE_JOINED',
  DETH_CLAIMED = 'DETH_CLAIMED',
  FEES_AND_MEV_CLAIMED = 'FEES_AND_MEV_CLAIMED',
  NODE_RUNNER_REWARDS_CLAIMED = 'NODE_RUNNER_REWARDS_CLAIMED',
  BLS_PUBLIC_KEY_WITHDRAWN = 'BLS_PUBLIC_KEY_WITHDRAWN',
  GIANT_LP_BURNED_FOR_DETH = 'GIANT_LP_BURNED_FOR_DETH',
  NODE_RUNNER_NAME_UPDATED = 'NODE_RUNNER_NAME_UPDATED',
  VALIDATOR_UNSTAKED = 'VALIDATOR_UNSTAKED',
  PROTECTED_STAKING_LP_BURNED = 'PROTECTED_STAKING_LP_BURNED',
  LSDN_DEPLOYED = 'LSDN_DEPLOYED',
  LP_TOKEN_TRANSFERRED = 'LP_TOKEN_TRANSFERRED',
  RAGE_QUIT_ASSISTANT_DEPLOYED = 'RAGE_QUIT_ASSISTANT_DEPLOYED',
  RAGE_QUIT_LP_MINTED = 'RAGE_QUIT_LP_MINTED',
  RAGE_QUIT_LP_BURNED = 'RAGE_QUIT_LP_BURNED',
  LSD_NAME_UPDATED = 'LSD_NAME_UPDATED',
  DAO_COMMISSION_UPDATED = 'DAO_COMMISSION_UPDATED',
  WHITELIST_STATUS_UPDATED = 'WHITELIST_STATUS_UPDATED',
  SWEEP_REPORTED = 'SWEEP_REPORTED',
  FINAL_SWEEP_REPORTED = 'FINAL_SWEEP_REPORTED',
  NODE_OPERATOR_CLAIMED_UNSTAKED_ETH = 'NODE_OPERATOR_CLAIMED_UNSTAKED_ETH',
  GIANT_SAVETH_CLAIMED_ETH_FROM_RAGEQUIT = 'GIANT_SAVETH_CLAIMED_ETH_FROM_RAGEQUIT',
  GIANT_MEV_CLAIMED_ETH_FROM_RAGEQUIT = 'GIANT_MEV_CLAIMED_ETH_FROM_RAGEQUIT',
  ETH_CLAIMED_FROM_GIANT_POOL_PARTIAL_WITHDRAWAL = 'ETH_CLAIMED_FROM_GIANT_POOL_PARTIAL_WITHDRAWAL',
  ETH_CLAIMED_FROM_PARTIAL_WITHDRAWAL = 'ETH_CLAIMED_FROM_PARTIAL_WITHDRAWAL'
}
